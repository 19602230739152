<template>
    <div>
        <div class="Opend">
            <div class="menu-item-wrap">
                <div class="menu-item" :class="{ 'act': pathAct == 'bonus-center' }"
                    @click="onMenuItem('bonus-center')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" />
                    </div>
                    <div>{{ $t("person.Bonus") }}</div>
                </div>
            </div>
            <div class="menus-flex">
                <div class="menu-item" @click="onMenuItem('Affiliate')">
                    <div class="icon">
                        <img src="@/assets/person/inviteSubordinates.png" />
                    </div>
                    <div class="text">{{ $t("person.invite") }}
                        <span v-if="shareData.extendRewardsEnableState === 'enable'">
                            {{ CurrencyType }} {{ shareData.extendRewardsAmt }}
                        </span>
                    </div>
                </div>
                <div class="menu-item" @click="toBonusRainPopup">
                    <div class="icon">
                        <img src="@/assets/person/redpacket.png" />
                    </div>
                    <div class="text">{{ $t("person.redPacket") }}</div>
                </div>
            </div>
            <div class="game_classify">
                <div class="game_classify_top">
                    <div :class="{ 'act': act == 'Popular' }" @click="onClassifyTop('Popular')">
                        <i class="iconfont icon-dianzanmw"></i>
                    </div>
                    <div :class="{ 'act': act == 'Favoritos', 'disabled': !isLogin }"
                        @click="isLogin && onClassifyTop('Favoritos')">
                        <i class="iconfont icon-zan"></i>
                    </div>
                    <div :class="{ 'act': act == 'Recente', 'disabled': !isLogin }"
                        @click="isLogin && onClassifyTop('Recente')">
                        <i class="iconfont icon-shijian"></i>
                    </div>
                </div>
                <div class="menu-item-wrap" v-for="(item, index) in gameClassify" :key="index"
                    @mouseenter="enterMenuItem(item)" @mouseleave="leaveMenuItem(item)">
                    <div class="menu-item"
                        :class="{ 'act': act == item.gameCategoryType || hoverAct == item.gameCategoryType }"
                        @click.stop="toCasino(item)">
                        <div class="icon">
                            <img class="img" :src="item.unActivationImg" />
                            <img class="imgAct" :src="item.activationImg" />
                        </div>
                        <div>{{ item.categoryShowName }}</div>
                        <div v-if="item.list && item.list.length" class="arrow iconfont icon-open" :class="{ 'botArrow': mobile && !item.isShowSub, 'topArrow': mobile && item.isShowSub }" @click.stop="onArrow(item)"></div>
                    </div>
                    <div v-if="item.isShowSub && item.list && item.list.length" class="mobile-sub-menus">
                        <div class="sub-menus-item" v-for="(citem, cindex) in item.list" :key="cindex"
                            @click.stop="toCasino(item, citem)">
                            <!-- <van-image width="0.38rem" height="0.3rem" fit="contain" :src="citem.iconImgUrl" />
                            <span>{{ citem.gamePlatformType }}</span> -->
                            <van-image width="2.08rem" fit="contain" :src="citem.iconImgUrl" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-item-wrap">
                <div class="menu-item" @click="onMenuItem('vip')">
                    <div class="icon">
                        <img class="img" src="@/assets/vip/vip.png" />
                        <img class="imgAct" src="@/assets/vip/vip.png" />
                    </div>
                    <div>{{ $t("person.VIPClub") }}</div>
                </div>
            </div>
            <div class="menu-group">
                <div class="menu-item" :class="{ 'act': pathAct == 'Affiliate' }" @click="onMenuItem('Affiliate')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_share.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_shareAct.png" />
                    </div>
                    <div>{{ $t("tabbar.Affiliate") }}</div>
                </div>
                <div class="menu-item" :class="{ 'act': pathAct == 'Event' }" @click="onMenuItem('Event')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_Promotion.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_PromotionAct.png" />
                    </div>
                    <div>{{ $t("person.Promotion") }}</div>
                </div>
                <div class="menu-item" :class="{ 'act': act == 'Bonus' }" @click="onShowDownAppPopup">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_Install.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_InstallAct.png" />
                    </div>
                    <div>{{ $t("person.Install") }}</div>
                </div>
                <div class="menu-item" :class="{ 'act': act == 'Bonus' }"
                    @click="onNavTo({ url: sysConfig.telegram, type: 'href' })">
                    <div class="icon">
                        <!-- <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" /> -->
                        <i class="iconfont icon-kefuE"></i>
                    </div>
                    <div>{{ $t("person.LiveChat") }}</div>
                </div>
            </div>
        </div>
        <div class="PC-hide">
            <div class="icon-item" @click="onMenuItem('bonus-center')">
                <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Bonus") }}</div>
            </div>
            <div class="icon-item" @click="onMenuItem('Affiliate')">
                <img
                    src="@/assets/person/inviteSubordinates.png" />
                <div v-if="true" class="tips">{{ $t("person.invite") }} {{ CurrencyType }}1,000</div>
            </div>
            <div class="icon-item" @click="toBonusRainPopup">
                <img
                    src="@/assets/person/redpacket.png" />
                <div v-if="true" class="tips">{{ $t("person.redPacket") }}</div>
            </div>
            <div class="game_classify">
                <div class="icon-item"
                    :class="{ 'arrow': (item.list && item.list.length), 'act': act == item.gameCategoryType || hoverAct == item.gameCategoryType }"
                    v-for="(item, index) in gameClassify" :key="index" @mouseenter="enterMenuItem(item)"
                    @mouseleave="leaveMenuItem(item)" @click.stop="toCasino(item)">
                    <img class="img" :src="item.unActivationImg" />
                    <img class="imgAct" :src="item.activationImg" />
                    <div v-if="false" class="tips">{{ item.categoryShowName }}</div>
                </div>
            </div>
            <div class="icon-item" @click="onMenuItem('vip')">
                <img src="@/assets/vip/vip.png" />
                <div v-if="true" class="tips">{{ $t("person.VIPClub") }}</div>
            </div>
            <div class="icon-item" @click="onMenuItem('Affiliate')">
                <img class="img" src="@/assets/sidebar/icon_share.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_shareAct.png" />
                <div v-if="true" class="tips">{{ $t("tabbar.Affiliate") }}</div>
            </div>
            <div class="icon-item" @click="onMenuItem('Event')">
                <img class="img" src="@/assets/sidebar/icon_Promotion.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_PromotionAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Promotion") }}</div>
            </div>
            <div class="icon-item" @click="onShowDownAppPopup">
                <img class="img" src="@/assets/sidebar/icon_Install.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_InstallAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Install") }}</div>
            </div>
            <div class="icon-item" @click="onNavTo({ url: sysConfig.telegram, type: 'href' })">
                <!-- <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" /> -->
                <i class="iconfont icon-kefuE"></i>
                <div v-if="true" class="tips">{{ $t("person.LiveChat") }}</div>
            </div>
        </div>
        <div class="sub-menus" :class="{ 'hover': isHover }" @mouseenter="enterMenuItem('sub')"
            @mouseleave="leaveMenuItem('sub')">
            <div class="sub-menus-item" :class="{ 'act': subAct == item.id }" v-for="(item, index) in PCSubMenus.list"
                :key="index" @click="toCasino(PCSubMenus, item)">
                <van-image width="2.08rem" fit="contain" :src="item.iconImgUrl" />
                <!-- <span>{{ item.gamePlatformType }}</span> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getGameClassify } from '@/api/game'
import { getShareinfo } from '@/api/share'
import { Toast } from 'vant'
export default {
    name: 'Sidebar',
    data() {
        return {
            act: 0,
            hoverAct: 0,
            hoverActTemp: 0,
            isHover: false,
            subAct: 0,
            isShowSub: false,
            gameClassify: [],
            PCSubMenus: [],
            pathAct: '',
            shareData: {}
        }
    },
    computed: {
        ...mapState({
            sidebar: state => state.app.sidebar,
            device: state => state.app.device
        }),
        mobile() {
            return this.device === 'mobile'
        },
        isLogin() {
            return !!this.$store.getters.token
        },
        sysConfig() {
            return this.$store.getters.sysConfig
        },
        isOpened() {
            return !this.sidebar.opened
        }
    },
    watch: {
        $route: {
            handler: function (to, from) {
                this.act = ''
                this.subAct = ''
                const opt = this.$route?.params
                if (opt && opt.params) {
                    const params = JSON.parse(decodeURIComponent(opt?.params))
                    this.act = params?.code
                    if (params.gameTitleId) this.subAct = params.gameTitleId
                }
                this.pathAct = this.$route.name
            },
            deep: true
        },
        isOpened: {
            handler: function (newVal, oldVal) {
                if (!this.mobile && this.gameClassify.length) {
                    this.gameClassify.forEach(x => {
                        x.isShowSub = false
                    })
                }
            },
            deep: true
        }
    },
    created() {
        this.pathAct = this.$route.name
        this.getGameClassify()
        this.getShareInfo()
    },
    methods: {
        onClassifyTop(act) {
            const item = {
                gameCategoryType: act,
                categoryShowName: act
            }
            this.toCasino(item)
        },
        onMenuItem(act) {
            if (this.pathAct === act) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.pathAct = act
            this.onNavTo({ url: act, type: 'post' })
        },
        enterMenuItem(item) {
            if (item !== 'sub') {
                this.PCSubMenus = item
                this.hoverAct = item.gameCategoryType
                this.hoverActTemp = item.gameCategoryType
            } else {
                this.hoverAct = this.hoverActTemp
            }
            if (!this.mobile) this.isHover = true
        },
        leaveMenuItem(item) {
            this.hoverAct = ''
            if (!this.mobile) this.isHover = false
        },
        getGameClassify() {
            getGameClassify().then(res => {
                const that = this
                if (res?.code) return
                const D = res?.result.filter(x => x.gameTitleIPage && x.gameTitleIPage.records && x.gameTitleIPage.records.length && x.gameCategoryType !== 'None')
                D.forEach(x => {
                    x.isLoading = 0
                    x.pageNum = 1
                    x.hasMore = true
                    x.list = []
                    x.isShowSub = false
                    if (x.gameTitleIPage && x.gameTitleIPage.records.length) {
                        x.classifyType = 'manufacturer' // 是厂商列表
                        x.list = x.gameTitleIPage.records
                        x.hasMore = x.gameTitleIPage.total < x.gameTitleIPage.records.length
                        x.total = x.gameTitleIPage.total
                    }
                })
                that.gameClassify = D
            })
        },
        onArrow(item) {
            if (this.mobile) {
                item.isShowSub = !item.isShowSub
            } else {
                this.toCasino(item)
            }
        },
        toCasino(item, citem) {
            if (this.act === item.gameCategoryType && !citem) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.act = item.gameCategoryType
            const obj = {
                iconImg: item.activationImg,
                code: item.gameCategoryType,
                title: item.categoryShowName
            }
            if (this.act === item.gameCategoryType && citem && this.subAct === citem.id) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            if (citem) {
                this.subAct = citem.id
                obj.gamePlatformType = citem.gamePlatformType
                obj.gameTitleId = citem.id
                obj.gameFactoryName = citem.gameFactoryName
            } else if (item.list && item.list.length) {
                const subItem = item.list[0]
                this.subAct = subItem.id
                obj.gamePlatformType = subItem.gamePlatformType
                obj.gameTitleId = subItem.id
                obj.gameFactoryName = subItem.gameFactoryName
            }
            const params = encodeURIComponent(JSON.stringify(obj))
            const timestamp = new Date().getTime()
            this.onNavTo({ url: 'Casino', type: 'post', params: { timestamp, params } })
        },
        toBonusRainPopup() {
            this.$store.dispatch('user/setIsShowRedPacketPopup', { show: true })
        },
        onShowDownAppPopup() {
            this.$store.dispatch('user/setIsShowDownAppPopup', true)
        },
        getShareInfo() {
            getShareinfo().then((res) => {
                if (res?.code) return
                const D = res.result
                this.shareData = D
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';

.Opend {
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 18px 10px;
    padding-right: 0;
    overflow: auto;
    width: 260px;
    background: #27005F;
    box-shadow: 8px 0 8px #00000040;
    transition-duration: 0.3s;
    position: relative;
    z-index: 2;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
    }
}

.PC-hide {
    box-sizing: border-box;
    width: 64px;
    height: 100%;
    background: #27005F;
    box-shadow: 8px 0 8px #00000040;
    display: none;
    pointer-events: none;
    transform: translateY(0);
    position: relative;
    z-index: 2;
    padding: 18px 0;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
    }

    .icon-item {
        height: 46px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
        cursor: pointer;
        position: relative;

        i.iconfont {
            font-size: .37rem;
            color: $font-sub-color;
            margin: 0 .14rem;
            display: block;

            &.icon-kefuE {
                color: $font-theme-color;
            }
        }

        .tips {
            display: none;
            position: absolute;
            right: -10px;
            height: 100%;
            transform: translateX(100%);
            border-radius: 12px;
            background: #27005f;
            width: auto;
            white-space: nowrap;
            color: #fff;
            line-height: 46px;
            padding: 0 10px;

            &::before {
                border: 8px solid transparent;
                -moz-border-radius: 10px;
                border-radius: 10px;
                border-right-color: #27005f;
                content: "";
                left: -14px;
                position: absolute;
                top: 15px;
                z-index: -1;
            }
        }

        &>img {
            width: 33px;
            height: 33px;
            vertical-align: middle;

            &.imgAct {
                display: none;
            }
        }

        &.vip {
            padding: 7px;

            &>img {
                width: 19px;
                height: 19px;
            }
        }

        &.act,
        &:hover {
            &>.tips {
                display: block;
            }

            &>img {
                &.imgAct {
                    display: block;
                }

                &.img {
                    display: none;
                }
            }

            &.arrow {
                &::after {
                    border-color: #C1C1C1;
                }
            }
        }

        &.arrow {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 1px;
                border-top: 2px solid;
                border-right: 2px solid;
                border-color: #C1C1C1;
                right: 10px;
                top: 6px;
            }
        }
    }
}

.menus-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin-right: 10px;

    &>.menu-item {
        padding: 0;
        padding-left: 7px;

        &:first-child {
            background: linear-gradient(-25deg, #BB4CD0, #5F17B3);
        }

        &:last-child {
            background: linear-gradient(-25deg, #5B06C2, #7D21F4);
        }

        .text {
            color: #fff;
            font-size: 12px;
            line-height: 12px;
            width: calc(100% - 34px);
            text-align: left;
            font-weight: bold;
        }
    }
}

.menu-item-wrap {
    padding-right: 10px;
}

.mobile-sub-menus {
    background: #160A08;
    border-radius: 0 0 12px 12px;
    padding: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &>.sub-menus-item {
        background: #27005F;
        border-radius: 12px;
        width: calc(50% - 2px);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $font-sub-color;
        cursor: pointer;

        &>span {
            margin-left: .16rem;
        }

        &.act,
        &:hover {
            color: #fff;
            font-weight: bold;
            background: #23262f;
        }
    }
}

.menu-item {
    width: 100%;
    background: #560DB0;
    color: #C1C1C1;
    height: 46px;
    font-size: 14px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 13px 0 20px;
    margin-top: 4px;
    cursor: pointer;
    position: relative;

    .icon {
        margin-right: 6px;

        i.iconfont {
            font-size: .36rem;
            color: $font-sub-color;
            margin: 0 .1rem;
            display: block;

            &.icon-kefuE {
                color: $font-theme-color;
            }
        }

        &>img {
            width: 33px;
            height: 33px;
            vertical-align: middle;

            &.imgAct {
                display: none;
            }
        }

        &.vip {
            padding: 7px;

            &>img {
                width: 19px;
                height: 19px;
            }
        }
    }

    &.act,
    &:hover {
        color: #fff;
        font-weight: bold;
        background: linear-gradient(-25deg, #BB4CD0, #5F17B3);

        .icon {
            &>img {
                &.imgAct {
                    display: block;
                }

                &.img {
                    display: none;
                }
            }
        }

        &.arrow {
            &::after {
                border-color: #fff;
            }
        }
    }

    .arrow {
        position: absolute;
        color: #A0A4A7;
        width: .8rem;
        height: .8rem;
        top: calc(50% - .4rem);
        right: 0;
        font-size: .26rem;
        transform: rotate(-90deg);
        display: flex;
        align-items: center;
        justify-content: center;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 10px;
        //     height: 10px;
        //     border-radius: 1px;
        //     border-top: 2px solid;
        //     border-right: 2px solid;
        //     border-color: #516382;
        //     right: 16px;
        //     top: 18px;
        //     transform: rotate(45deg);
        //     transition-duration: 0.3s;
        // }

        &.botArrow {
            transform: rotate(-180deg);
        }

        &.topArrow {
            border-radius: 12px 12px 0 0;
            transform: rotate(0deg);
            margin-top: .03rem;
        }
    }
}

.game_classify {
    margin: 24px 0;
    width: 100%;

    .game_classify_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding-right: 10px;

        &>div {
            width: 33.3333333%;
            height: 46px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #560DB0;
            color: #C1C1C1;
            cursor: pointer;

            &>i {
                font-size: 20px;
            }

            &.act,
            &:hover:not(.disabled) {
                color: #fff;
                background: linear-gradient(-25deg, #BB4CD0, #5F17B3);
            }

            &.disabled {
                opacity: .4;
                cursor: not-allowed;
            }
        }
    }
}

.menu-group {
    background: #27005F;
    border-radius: 12px;
    margin-top: 4px;
    overflow: hidden;
    margin-right: 10px;

    .menu-item {
        margin-top: 0;
        border-radius: 0;
    }
}

.sub-menus {
    height: 100%;
    position: absolute;
    width: 200px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #27005F;
    top: 0;
    right: -200px;
    box-shadow: 5px 0 5px #00000030;
    display: none;

    &.hover {
        display: block;
    }

    .sub-menus-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.8rem;
        padding: 0 18px;
        color: $font-sub-color;
        cursor: pointer;

        ::v-deep .van-image {
            .van-image__img {
                filter: grayscale(100%);

                &:hover {
                    filter: grayscale(0%);
                }
            }
        }

        &>span {
            margin-left: .16rem;
        }

        &.act {
            background: linear-gradient(-25deg, #BB4CD0, #5F17B3);

            ::v-deep .van-image {
                .van-image__img {
                    filter: grayscale(0%);
                }
            }
        }

        &:hover {
            color: #fff;
            font-weight: bold;
            background: linear-gradient(-25deg, #BB4CD0, #5F17B3);
        }
    }
}

html[data-device-nodesktop="1"] {
    .PC-hide {
        display: block;
    }
}

html[data-device-nodesktop="0"] {
    .PC-hide {
        display: none;
    }
}
</style>
