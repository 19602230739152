<template>
    <van-popup v-model="show" mode="center" @click-overlay="close">
        <div class="content">
            <div class="title">
                {{ $t('lang.tips') }}
            </div>
            <div class="tip">
                {{ $t('person.transferTips1') }}
                {{ CurrencyType }} {{ userMoney.bonusAmtAccount }}
                {{ $t('person.transferTips2') }}
            </div>
            <div class="btns">
                <div class="btn" @click="close">
                    {{ $t('lang.cancel') }}
                </div>
                <div class="btn" @click="onTransfer">
                    {{ $t('person.transferTips3') }}
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { transferTurnoverLimit, doTransfer } from '@/api/user'
import { Toast } from 'vant'
export default {
    data() {
        return {
            show: false
        }
    },
    computed: {
        userMoney() {
            const userInfo = this.$store.getters.userInfo
            const info = {
                balanceAmtAccount: userInfo.balanceAmtAccount ? userInfo.balanceAmtAccount.useBalance.toFixed(2) : '',
                bonusAmtAccount: userInfo.balanceAmtAccount ? userInfo.bonusAmtAccount.useBalance.toFixed(2) : ''
            }
            return info
        }
    },
    methods: {
        open() {
            console.log(this.userMoney.bonusAmtAccount, 'this.userMoney.bonusAmtAccount====')
            if (this.userMoney && Number(this.userMoney.bonusAmtAccount)) {
                transferTurnoverLimit({
                    turnAmt: this.userMoney.bonusAmtAccount
                }).then((res) => {
                    if (res.code) return
                    this.show = true
                    // this.tranferMoney = res.result
                })
            } else {
                Toast(this.$t('person.transferMsg'))
            }
        },
        close() {
            this.show = false
        },
        // 转移奖金
        async onTransfer() {
            if (this.userMoney && Number(this.userMoney.bonusAmtAccount)) {
                const { success } = await doTransfer({
                    turnAmt: this.userMoney.bonusAmtAccount
                })
                if (success) {
                    this.show = false
                    this.$emit('confirm')
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.van-popup {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.content {
    padding: .2rem .3rem .3rem;
    box-sizing: border-box;
    width: 6rem;
    background: $bg-popup-color;
    border-radius: .14rem;
    position: relative;
    color: #ffffff;
    font-size: .24rem;
    text-align: center;
    padding-top: .5rem;

    .title {
        font-size: .34rem;
        color: #fff;
        line-height: .7rem;
        // background-image: url(@/assets/common/top.png);
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // background-position: top left;
        height: .7rem;
        width: 4.2rem;
        position: absolute;
        left: calc(50% - 2.1rem);
        top: 0;
    }

    .tip {
        margin: .3rem 0 .65rem;
        height: .36rem;
        line-height: .36rem;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        column-gap: .3rem;
        height: .7rem;
        line-height: .66rem;

        .btn {
            flex: 1;
            padding: 0 .1rem;
            border-radius: .14rem;
            cursor: pointer;

            &:first-child {
                color: $font-theme-color;
                border: .01rem solid $font-theme-color;
            }

            &:last-child {
                color: $font-btn-color;
                background: $bg-btn-color;
            }
        }
    }
}
</style>
