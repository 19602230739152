<template>
    <div class="popup" :class="classObj">
        <van-popup v-model="show" @click-overlay="close">
            <div class="content">
                <div class="header">
                    <div>{{ $t("bankset.PleaseSelectChannelName") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="main_content">
                    <div class="search_box">
                        <a-input v-model="keyword" placeholder="search" allowClear @pressEnter="newSearch">
                            <div slot="suffix" class="searchbtn" @click="newSearch">
                                <i class="iconfont icon-sousuo"></i>
                            </div>
                        </a-input>
                    </div>
                    <div class="table">
                        <div class="tBody">
                            <van-radio-group v-if="list.length" v-model="channelName">
                                <van-radio :name="item" checked-color="#FFB000" v-for="(item, index) in list"
                                    :key="index">{{ item }}</van-radio>
                            </van-radio-group>
                            <div v-else class="nodata">
                                <image src="/static/imgs/empty/empty.png" mode="widthFix"></image>
                                <div class="empty">{{ $t("lang.empty") }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="btns">
                        <div @click="close">{{ $t("lang.cancel") }}</div>
                        <div @click="onConfirm">{{ $t("lang.confirm") }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getBankListNigeria, getBankListRussia } from '@/api/withdraw'
export default {
    name: 'channelPopup',
    data() {
        return {
            show: false,
            channelName: '',
            list: [],

            keyword: ''
        }
    },
    props: {
        name: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.show = false
        },
        async open() {
            this.show = true
            this.list = []
            this.channelName = this.name
            let result
            if (this.BelongNation === 'Nigeria') {
                result = await getBankListNigeria()
            } else if (this.BelongNation === 'Russia') {
                result = await getBankListRussia()
            }
            const D = result.result
            this.list = D
            if (!this.channelName) this.channelName = D[0]
            this.$nextTick(() => { })
        },
        inputSearch() {
            if (!this.keyword) this.open()
        },
        newSearch() {
            const keyword = this.keyword
            this.list = this.list.filter(x => x.indexOf(keyword) !== -1)
        },
        onConfirm() {
            console.log(this.channelName)
            this.$emit('onConfirm', this.channelName)
            this.close()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            border-radius: .2rem;
            width: 500px;
            max-width: 93%;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 1rem;
                padding-left: .46rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: .1rem;
                // background: #0F212E;

                &>div:first-child {
                    font-size: .32rem;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: .36rem;
                    font-weight: bold;
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .main_content {
                padding: .28rem .28rem;
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            ::v-deep input::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input[type='number'] {
                -moz-appearance: textfield;
            }

            ::v-deep .ant-input-suffix {
                .searchbtn {
                    height: .6rem;
                    width: .8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background: $bg-btn-color;
                    font-size: 0.28rem;
                    color: $font-btn-color;
                    text-align: center;
                    margin-right: .12rem;
                    border-radius: .12rem;
                }

                .ant-input-clear-icon {
                    margin-right: .12rem;
                }
            }

            ::v-deep .ant-input {
                background: $bg-input-color !important;
                border-radius: 0.14rem;
                font-size: 0.22rem;
                height: .8rem;
                padding: 0px 0.24rem !important;
                color: #e3e3e3;
                border: .02rem solid $border-input-color !important;
                padding-right: 1rem !important;

                &:focus {
                    background: $bg-input-act-color !important;
                    border-color: $border-active-color !important;
                }

                &::-webkit-input-placeholder {
                    /* WebKit browsers */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                    opacity: .7;
                    font-weight: 100;
                }

                &:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }
            }

            .table {
                border-radius: .1rem;
                // background: $bg-box-color;
                padding: .24rem;
                width: 100%;
                flex: 1;
                position: relative;
                margin-top: .2rem;

                .tBody {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: auto;

                    .tr {
                        display: flex;
                        align-items: center;
                        padding: .2rem 0;

                        &:nth-child(2n) {
                            background: $bg-list-color;
                            border-radius: .12rem;
                        }

                        &>div {
                            width: 100%;
                            color: $font-sub-color;
                            font-size: .2rem;
                            display: flex;
                            align-items: center;
                        }

                    }

                    ::v-deep .van-radio {
                        margin-bottom: .1rem;
                        padding: .1rem;

                        &:nth-child(2n) {
                            background: $bg-list-color;
                            border-radius: .12rem;
                        }

                        .van-radio__label {
                            text-align: left;
                            color: $font-label-color;
                            font-size: .24rem;
                        }

                        .van-radio__icon--checked {
                            &+.van-radio__label {
                                color: #fff;
                            }
                        }
                    }

                    .nodata {
                        width: 100%;
                        height: 4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        &>image {
                            width: 3rem;
                            height: 2rem;
                        }

                        .empty {
                            margin-top: .41rem;
                            text-align: center;
                            color: $font-empty-color;
                            font-size: .24rem;
                        }
                    }
                }
            }

            .btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: .2rem;
                padding-top: .2rem;

                &>div {
                    width: 50%;
                    text-align: center;
                    line-height: .6rem;
                    border-radius: .1rem;
                }

                &>div:first-child {
                    border: .01rem solid $font-theme-color;
                    color: $font-theme-color;
                }

                &>div:last-child {
                    border: .01rem solid transparent;
                    background: $bg-btn-color;
                    color: $font-btn-color;
                }
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        ::v-deep .content {
            width: 100% !important;
            height: 100% !important;
        }

        ::v-deep .banner_pc {
            display: none;
        }

        ::v-deep .LogReg {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
</style>
