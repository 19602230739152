<template>
    <div class="popup" :class="classObj" v-if="isLogin">
        <van-popup :value="show" @click-overlay="close" @open="openPop">
            <div class="content">
                <div class="header">
                    <div>{{ $t("BetsHistory.BetsHistory") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="chose_box">
                        <div class="date_box">
                            <a-date-picker mode="date" v-model="nowDate" :format="'YYYY/MM/DD'" :open="open"
                                :getCalendarContainer="getCalendarContainer" @openChange="openChange" @change="onChange"
                                :defaultValue="todayDate" :allowClear="false" :showToday="false" />
                        </div>
                    </div>
                    <div class="list_content">
                        <van-list v-model="loading" :finished="!hasMore" loading-text="" @load="getBetRecordsList"
                            class="list" offset="50" :immediate-check=false v-if="list.length !== 0">
                            <div class="list_item" v-for="(item, index) in list" :key="index">
                                <div>
                                    <div>
                                        {{ $t("MyProfile.ID") }}:{{ item.transactionId }}
                                    </div>
                                    <div>
                                        {{ item.createTime }}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {{ item.gameName }}
                                    </div>
                                    <div>
                                        {{ CurrencyType }} {{ item.winAmt }}
                                    </div>
                                </div>
                            </div>
                        </van-list>
                        <div class="empty" v-else>
                            <img src="@/assets/common/empty.png" alt="" class="empty_img">
                            <div>{{ $t("lang.NoData") }}</div>
                        </div>
                        <div v-if="loading && list.length !== 0" class="loading">
                            <a-spin>
                                <a-icon slot="indicator" type="loading" spin />
                            </a-spin>
                            {{ $t("lang.Loading") }}
                        </div>
                        <div v-if="!hasMore && list.length !== 0" class="loading">
                            {{ $t("lang.NoMore") }}
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    getBetRecordsList
} from '@/api/records'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    name: 'betHistory',
    components: {},
    data() {
        return {
            firstLoaded: true,
            dateFormat: 'YYYY/MM/DD',
            todayDate: '',
            nowDate: '',
            open: false,
            list: [],
            pageSize: 10,
            pageNo: 0,
            loading: false,
            hasMore: true
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        },
        show: {
            get() {
                return this.$store.getters.isShowBetHistoryPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() {
        this.todayDate = moment()
    },
    methods: {
        moment,
        close() {
            this.$store.dispatch('user/setIsShowBetHistoryPopup', false)
        },
        openPop() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        init() {
            this.pageSize = 10
            this.pageNo = 0
            this.list = []
            this.nowDate = this.todayDate
            this.getBetRecordsList()
        },
        async getBetRecordsList() {
            this.pageNo++
            const curData = {
                startTime: '',
                endTime: '',
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }
            // console.log(this.nowDate)
            if (this.nowDate) {
                curData.startTime = moment(this.nowDate).format('YYYY-MM-DD 00:00:00')
                curData.endTime = moment(this.nowDate).format('YYYY-MM-DD 23:59:59')
            }
            this.loading = true
            const {
                result,
                code
            } = await getBetRecordsList(curData)
            this.loading = false
            if (code === 0) {
                this.list = [...this.list, ...result.list]
                this.pages = result.pages
                this.hasMore = Number(result.total) > this.list.length
                this.firstLoaded = false
            }
        },
        onChange(value, dateString) {
            // console.log('Selected Time: ', value)
            // console.log('Formatted Selected Time: ', dateString)
            this.nowDate = value
            this.pageSize = 10
            this.pageNo = 0
            this.list = []
            this.getBetRecordsList()
        },
        openChange(status) {
            if (status) {
                this.open = true
            } else {
                this.open = false
            }
        },
        getCalendarContainer() {
            return document.querySelector('.date_box')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .content {
            width: 100% !important;
            height: 100% !important;
        }

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }

            .content {
                background: $bg-color;
                width: 100%;
                height: 100vh;

                .header {
                    height: 1.12rem;
                    padding: 0 .3rem;
                    background: $bg-navbar-color;

                    &>div:first-child {
                        font-size: .32rem;
                    }

                    .close {
                        font-size: .32rem;
                    }
                }

                .box {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem;
                }

                .chose_box {

                    .date_box {
                        text-align: left;

                        ::v-deep .ant-calendar-picker-input {
                            &.ant-input {
                                background: $bg-box-color;
                                padding: .22rem .24rem;
                                height: max-content;
                                color: #ffffff;
                                border-radius: .24rem;
                                font-size: .24rem;
                                border: 0;
                            }
                        }

                        ::v-deep .ant-calendar-picker-icon {
                            font-size: .32rem;
                        }
                    }
                }

                .list_content {
                    // background: $bg-box-color;
                    background: $bg-menu-color;
                    margin: .2rem 0;
                    flex: 1;

                    .list {
                        padding: .2rem;
                        row-gap: .2rem;

                        .list_item {
                            padding: .2rem;
                            border-radius: .24rem;

                            >div {
                                display: flex;
                                justify-content: space-between;
                                font-size: .26rem;

                                &:first-child {
                                    margin-bottom: .04rem;

                                    >div {

                                        &:last-child {
                                            font-size: .22rem;
                                        }
                                    }
                                }

                                &:last-child {
                                    >div {
                                        &:first-child {
                                            font-size: .28rem;
                                        }
                                    }
                                }
                            }

                            ::v-deep .van-loading {
                                display: none;
                            }
                        }
                    }

                    .loading {
                        font-size: .28rem;
                    }

                    .empty {
                        font-size: .3rem;

                        .empty_img {
                            width: 2.8rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 800px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 30px 40px;
            }

            .chose_box {

                .date_box {
                    text-align: left;

                    // color: #ffffff;
                    // padding: 15px 12px;
                    ::v-deep .ant-calendar {
                        background: $bg-box-color;
                    }

                    ::v-deep .ant-calendar-picker {
                        width: 50%;
                    }

                    ::v-deep .ant-calendar-input {
                        color: #ffffff;
                        background: transparent !important;
                    }

                    ::v-deep .ant-calendar-picker-input {
                        &.ant-input {
                            background: $bg-input-color;
                            padding: 12px;
                            height: max-content;
                            color: #ffffff;
                            border-radius: .24rem;
                            font-size: 15px;
                            border: 0;
                        }
                    }

                    ::v-deep .ant-calendar-picker-icon {
                        color: $font-iconfont-color;
                        font-size: 16px;
                    }

                    ::v-deep .ant-calendar-picker-container {
                        color: $font-label-color;
                    }

                    ::v-deep .ant-calendar-today {
                        .ant-calendar-date {
                            border-color: transparent;
                        }
                    }

                    ::v-deep .ant-calendar-selected-day {
                        .ant-calendar-date {
                            background: $bg-btn-color;
                            color: $font-btn-color;
                            border-radius: 50%;
                        }
                    }

                    ::v-deep .ant-calendar-date {
                        color: #ffffff;
                    }

                    ::v-deep .ant-calendar-month-select {
                        color: #ffffff;
                    }

                    ::v-deep .ant-calendar-year-select {
                        color: #ffffff;
                    }
                }
            }

            .list_content {
                background: $bg-menu-color;
                border-radius: .24rem;
                margin-top: 10px;
                flex: 1;

                .list {
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    .list_item {
                        padding: 15px;
                        background: $bg-box-color;
                        border-radius: .24rem;

                        >div {
                            display: flex;
                            justify-content: space-between;
                            font-size: 16px;

                            &:first-child {
                                margin-bottom: 2px;

                                >div {
                                    &:first-child {
                                        color: #ffffff;
                                    }

                                    &:last-child {
                                        font-size: 15px;
                                        color: $font-label-color;
                                    }
                                }
                            }

                            &:last-child {
                                >div {
                                    &:first-child {
                                        font-size: 18px;
                                        color: #ffffff;
                                        font-weight: bold;
                                    }

                                    &:last-child {
                                        color: $font-theme-color;
                                    }
                                }
                            }
                        }

                        ::v-deep .van-loading {
                            display: none;
                        }
                    }
                }

                .loading {
                    font-size: 14px;
                    color: $font-sub-color;
                }

                .empty {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $font-sub-color;
                    font-size: 16px;

                    .empty_img {
                        width: 170px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>
