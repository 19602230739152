<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("bankset.BankCardDetails") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="main_content">
                    <banksetNigeria v-if="BelongNation === 'Nigeria'" ref="banksetNigeria" @close="close"
                        @succ="getBank"></banksetNigeria>
                    <banksetRussia v-else-if="BelongNation === 'Russia'" ref="banksetRussia" @close="close"
                        @succ="getBank"></banksetRussia>
                    <bankSetBrazil v-else ref="bankSetBrazil" @close="close" @succ="getBank"></bankSetBrazil>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import statusBar from '@/components/statusBar'
import banksetNigeria from './banksetNigeria.vue'
import banksetRussia from './banksetRussia.vue'
import bankSetBrazil from './bankSetBrazil.vue'
export default {
    name: 'bankSet',
    components: { statusBar, banksetNigeria, banksetRussia, bankSetBrazil },
    data() {
        return {}
    },
    props: {
        isCanClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        show: {
            get() {
                return this.$store.getters.isShowBankSetPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowBankSetPopup', false)
        },
        open() {
            this.$nextTick(() => {
                if (this.BelongNation === 'Pakistan') {
                    // uni.navigateTo({
                    //     url: '/pages/withdraw/banksetPakistan'
                    // })
                } else if (this.BelongNation === 'Nigeria') {
                    this.$refs.banksetNigeria.open()
                } else if (this.BelongNation === 'Russia') {
                    this.$refs.banksetRussia.open()
                } else {
                    this.$refs.bankSetBrazil.open()
                }
            })
        },
        getBank() {
            this.$emit('getBank')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            border-radius: .2rem;
            width: 500px;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .header {
                display: flex;
                height: 1rem;
                padding-left: .46rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: .1rem;
                // background: #0F212E;

                &>div:first-child {
                    font-size: .32rem;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: .36rem;
                    font-weight: bold;
                    height: 1rem;
                    width: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .main_content {
                overflow: auto;
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            padding-left: 0 !important;
            max-height: 100% !important;
        }
    }
}
</style>
