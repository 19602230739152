<template>
    <div class="footer">
        <div class="classify">
            <div class="classify-sub hide" :class="{ 'act': act1 }" @click="act1 = !act1">
                <h4 class="classify-sub-title arrow botArrow">{{ $t('footer.Promo') }}</h4>
                <div class="classify-sub-list">
                    <div class="classify-sub-item" :class="{ 'act': pathAct == 'vip' }" @click="onMenuItem('vip')">{{ $t('footer.VIPClub') }}</div>
                    <div class="classify-sub-item" :class="{ 'act': pathAct == 'Event' }" @click="onMenuItem('Event')">{{ $t('footer.Promotions') }}</div>
                    <div class="classify-sub-item" :class="{ 'act': pathAct == 'Affiliate' }" @click="onMenuItem('Affiliate')">{{ $t('footer.ReferAndEarn') }}</div>
                    <div class="classify-sub-item" :class="{ 'act': pathAct == 'bonus-center' }" @click="onMenuItem('bonus-center')">{{ $t('footer.Bonus') }}</div>
                </div>
            </div>
            <div class="classify-sub hide" :class="{ 'act': act2 }" @click="act2 = !act2">
                <h4 class="classify-sub-title arrow botArrow">{{ $t('footer.AboutUs') }}</h4>
                <div class="classify-sub-list">
                    <div class="classify-sub-item" @click="toPdfViewer(1)">{{ $t('footer.About') + ' ' + sysConfig.projectName }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(2)">{{ $t('footer.DataProtectionPolicy') }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(3)">{{ $t('footer.BusinessContinuityPolicy') }}</div>
                </div>
            </div>
            <div class="classify-sub hide" :class="{ 'act': act3 }" @click="act3 = !act3">
                <h4 class="classify-sub-title arrow botArrow">{{ $t('footer.Help') }}</h4>
                <div class="classify-sub-list">
                    <div class="classify-sub-item" @click="toPdfViewer(4)">AML</div>
                    <div class="classify-sub-item" @click="toPdfViewer(5)">{{ $t('footer.PrivacyPolicy') }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(6)">{{ $t('footer.ServiceTerms') }}</div>
                    <!-- <div class="classify-sub-item" @click="toPdfViewer(7)">{{ $t('footer.GamblingAddictionWarnings') }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(8)">{{ $t('footer.GamblingTreatmentCenters') }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(9)">{{ $t('footer.ResponsibleGambling') }}</div>
                    <div class="classify-sub-item" @click="toPdfViewer(10)">FAQ</div> -->
                </div>
            </div>
            <div class="classify-sub hide" :class="{ 'act': act4 }" @click="act4 = !act4">
                <h4 class="classify-sub-title arrow botArrow">{{ $t('footer.ContactUs') }}</h4>
                <div class="classify-sub-list">
                    <div v-if="sysConfig && sysConfig.telegramGroup" class="classify-sub-item"
                        @click="onNavTo({ url: sysConfig.telegramGroup, type: 'href' })">{{ $t('footer.JoinOurGroup') }}
                    </div>
                    <div v-if="sysConfig && sysConfig.telegram" class="classify-sub-item"
                        @click="onNavTo({ url: sysConfig.telegram, type: 'href' })">{{ $t('footer.ContactViaTelegram') }}
                    </div>
                    <div v-if="sysConfig && sysConfig.instagram" class="classify-sub-item"
                        @click="onNavTo({ url: sysConfig.instagram, type: 'href' })">Instagram</div>
                    <div v-if="sysConfig && sysConfig.onlineService" class="classify-sub-item"
                        @click="onNavTo({ url: sysConfig.onlineService, type: 'href' })">{{ $t('footer.OnlineServer') }}
                    </div>
                </div>
            </div>
            <div class="classify-sub icons">
                <h4 class="classify-sub-title">{{ $t('footer.JoinourCommunity') }}</h4>
                <div class="classify-sub-list">
                    <img v-for="(item, index) in platforms" :key="index" :src="item.icon" @click="onNavTo({ url: item.url, type: 'href' })"/>
                </div>
            </div>
        </div>
        <div class="desc">
            <div class="desc-item">
                <img class="logo" :src="logoUrl" />
                <div class="desc-content">{{ sysConfig.explainDec }}</div>
            </div>
            <div class="desc-item">
                <div class="desc-item-logos">
                    <img class="logo logo2" src="@/assets/common/footer-icon-2.png" />
                    <img v-if="BelongNation === 'Russia'" class="logo logo3" src="@/assets/common/basic-large-valid-seal.png" alt="Anjouan Licensing Services Inc." @click="toValidate">
                </div>
                <div class="desc-content">
                    <div>
                        {{ $t('footer.tips1') }}<br />
                        {{ $t('footer.tips2') }}<br />
                        {{ $t('footer.tips3') }}
                    </div>
                    <div class="copyright">{{ sysConfig.copyright }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { domainName } from '@/common/http'
export default {
    name: 'Footer',
    data() {
        return {
            act1: false,
            act2: false,
            act3: false,
            act4: false,
            pathAct: '',
            platforms: []
        }
    },
    computed: {
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        },
        sysConfig() {
            return this.$store.getters.sysConfig || {}
        }
    },
    watch: {
        $route: {
            handler: function (to, from) {
                this.pathAct = this.$route.name
            },
            deep: true
        },
        sysConfig: {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.GetPlatforms()
                }
            },
            deep: true
        }
    },
    created() {
        this.GetPlatforms()
        this.pathAct = this.$route.name
    },
    methods: {
        onMenuItem(act) {
            if (this.pathAct === act) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.pathAct = act
            this.onNavTo({ url: act, type: 'post' })
        },
        toPdfViewer(act) {
            let url = ''
            if (act === 1) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/About_en.pdf'
            }
            if (act === 2) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/Data_Protection_Policy.pdf'
            }
            if (act === 3) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/Business_Continuity_Policy.pdf'
            }
            if (act === 4) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/AML_en.pdf'
            }
            if (act === 5) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/Privacy_Policy_en.pdf'
            }
            if (act === 6) {
                url = 'https://laranja9.s3.sa-east-1.amazonaws.com/pdf/ww-win/Terms_Conditions_en.pdf'
            }
            if (act === 7) {
                url = 'https://img.engames.com/ak/Gambling_Addiction_Warnings.pdf'
            }
            if (act === 8) {
                url = 'https://img.engames.com/ak/Gambling_Treatment_Centers_in_Nigeria.pdf'
            }
            if (act === 9) {
                url = 'https://img.engames.com/ak/Responsible_Gambling.pdf'
            }
            if (act === 10) {
                url = 'https://img.engames.com/ak/FAQ_en.pdf'
            }
            this.onNavTo({ url, type: 'href' })
        },
        GetPlatforms() {
            this.platforms = []
            const systemConfig = this.sysConfig
            const facebook = systemConfig.facebook?.trim()
            if (facebook) {
                const platform1 = {
                    id: 0,
                    icon: require('@/assets/share/icon_fackbook.png'),
                    title: 'Facebook',
                    url: facebook,
                    linkType: 'href'
                }
                this.platforms.push(platform1)
            }
            const telegram = systemConfig.telegram?.trim()
            if (telegram) {
                const platform2 = {
                    id: 1,
                    icon: require('@/assets/share/icon_telegram.png'),
                    title: 'Telegram',
                    url: telegram,
                    linkType: 'href'
                }
                this.platforms.push(platform2)
            }
            const instagram = systemConfig.instagram?.trim()
            if (instagram) {
                const platform3 = {
                    id: 2,
                    icon: require('@/assets/share/icon_ins.png'),
                    title: 'Instagram',
                    url: instagram,
                    linkType: 'href'
                }
                this.platforms.push(platform3)
            }
            const youTube = systemConfig.youTube?.trim()
            if (youTube) {
                const platform4 = {
                    id: 3,
                    icon: require('@/assets/share/icon_youtube.png'),
                    title: 'Youtube',
                    url: youTube,
                    linkType: 'href'
                }
                this.platforms.push(platform4)
            }
            const whatsapp = systemConfig.whatsapp?.trim()
            if (whatsapp) {
                const platform5 = {
                    id: 4,
                    icon: require('@/assets/share/icon_whatsapp.png'),
                    title: 'Whatsapp',
                    url: whatsapp,
                    linkType: 'href'
                }
                this.platforms.push(platform5)
            }
            const twitter = systemConfig.twitter?.trim()
            if (twitter) {
                const platform6 = {
                    id: 5,
                    icon: require('@/assets/share/icon_x.png'),
                    title: 'X',
                    url: twitter,
                    linkType: 'href'
                }
                this.platforms.push(platform6)
            }
            const tiktok = systemConfig.tiktok?.trim()
            if (tiktok) {
                const platform7 = {
                    id: 6,
                    icon: require('@/assets/share/icon_tiktok.png'),
                    title: 'TikTok',
                    url: tiktok,
                    linkType: 'href'
                }
                this.platforms.push(platform7)
            }
        },
        toValidate() {
            window.location.href = 'https://verificationanjouangaming.org/validate2?domain=' + domainName + '&seal_id=d98a32c1eeb437e1bc07177a72c2347a9318592742ae1dcc78758b2e837b106b6aef7a82c9e07ec964bfd3e4a5d98335&stamp=b9867240c09b062b7bc71f9e4092c20e'
            // this.onNavTo({ url: '/Validate', type: 'get', params: { domain: domainName, seal_id: 'd98a32c1eeb437e1bc07177a72c2347a9318592742ae1dcc78758b2e837b106b6aef7a82c9e07ec964bfd3e4a5d98335', stamp: 'b9867240c09b062b7bc71f9e4092c20e' } })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';
.footer {
    width: 100%;
    background: #27005F;
    box-sizing: border-box;
    padding-top: 35px;
    padding-bottom: 35px;
}

.classify {
    width: 100%;
    display: flex;
    // column-gap: 5%;
    margin: 0 auto;
    max-width: 1414px;
    min-width: 720px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;

    .classify-sub {
        width: calc((100% - 251px) / 4);

        &.icons {
            width: 251px !important;
            flex-shrink: 0;

            .classify-sub-list {
                display: flex;
                flex-wrap: wrap;
                gap: .3rem;

                &>img {
                    width: .7rem;
                    height: .7rem;
                    cursor: pointer;
                }
            }
        }

        .classify-sub-title {
            width: 100%;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: .5rem;
            text-align: left;
        }

        .classify-sub-list {
            font-size: 14px;
            text-align: left;

            .classify-sub-item {
                color: #B88FF6;
                line-height: .24rem;
                font-size: .22rem;
                margin-bottom: .16rem;
                cursor: pointer;

                &:hover {
                    color: #fff;
                }

                &.act {
                    color: #fff;
                }
            }
        }
    }
}

.desc {
    display: flex;
    text-align: left;
    width: 100%;
    // column-gap: 5%;
    margin: 0 auto;
    margin-top: .6rem;
    max-width: 1414px;
    min-width: 720px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 1.2rem;

    .desc-item {
        width: calc(50% - .6rem);
        .desc-item-logos{
            display: flex;
            align-items: center;
            .logo {
                margin: 0;
            }
        }
    }

    .logo {
        height: .6rem;
        width: auto;
        margin-bottom: .24rem;
    }
    .logo2{
        height: 1.5rem;
    }
    .logo3{
        height: 1rem;
        margin-left: .1rem !important;
        cursor: pointer;
    }

    .desc-content {
        color: $font-sub-color;
        font-size: .26rem;

        .copyright {
            margin-top: .5rem;
        }
    }
}
</style>
