export default {
    lang: {
        LoadMore: 'Carregar mais',
        AllLoaded: 'Tudo carregado',
        ChangeLanguage: 'Mudar idioma',
        ChangePassword: 'Mudar senha',
        copySuccess: 'Cópia bem-sucedida',
        copyError: 'Falha na cópia',
        CopyEmptyTips: 'O conteúdo copiado não pode estar vazio',
        empty: 'Aqui está vazio',
        tips: 'Dicas',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        more: 'Mais',
        isCloseGame: 'Você deseja fechar o jogo?',
        back: 'Voltar',
        Claim: 'Reivindicar',
        Loading: 'Carregando',
        Play: 'Jogar',
        Download: 'Baixar',
        NoData: 'Ops! Nenhum dado ainda!',
        NoMore: 'Sem mais',
        CurrentlyPage: 'Atualmente nesta página'
    },
    tabbar: {
        Lobby: 'Início',
        Earn: 'Compartilhar',
        Game: 'Jogo',
        Event: 'Evento',
        Promotion: 'Promoção',
        Account: 'Perfil',
        Recharge: 'Recarregar',
        serve: 'Serviço',
        Deposit: 'Depósito',
        Affiliate: 'Afiliado'
    },
    home: {
        Download: 'Baixar',
        DownloadTips: 'Baixe o aplicativo e ganhe ',
        Search: 'Pesquisar',
        Popular: 'Popular',
        Recent: 'Recente',
        Favorite: 'Favorito',
        Providers: 'Provedores',
        CollectionSucc: 'Coleta bem-sucedida',
        CollectionCanceled: 'Coleta cancelada',
        MORE: 'MAIS',
        Top: 'Topo',
        greatBonus: 'Ótimo bônus para depósito'
    },
    login: {
        Login: 'Entrar',
        SignIn: 'Fazer login',
        SignUp: 'Inscrever-se',
        RememberMe: 'Lembrar de mim',
        ForgetPassword: 'Esqueceu a senha?',
        LoginTips: 'Para visitar este site, certifique-se de que você tem mais de 18 anos e concorda?',
        TermsOfService: 'Termos de Serviço',
        jumpRegister: 'Pular para registro',
        JumpLogin: 'Pular para login',
        noAccountTips: 'Não tem uma conta?',
        hasAccountTips: 'Você tem uma conta?',
        Email: 'Email',
        VerificationCode: 'Código de verificação',
        Send: 'Enviar',
        RestPassword: 'Redefinir senha',
        passwordTips: 'Senha (6-16)',
        passwordTips2: 'Digite a senha novamente',
        password: 'Senha',
        phone: 'Número de telefone',
        ReferralCode: 'Código de referência',
        CreateAccount: 'Criar conta',
        PleaseEnterYourPhoneNumber: 'Por favor, insira seu número de telefone',
        PleaseEnterYourPassword: 'Por favor, insira sua senha',
        ThePasswordMustContain6To16Characters: 'A senha deve conter de 6 a 16 caracteres',
        InconsistentPassword: 'A senha não corresponde',
        PleaseEnterInvitationCode: 'Por favor, insira o código de convite',
        PleaseEnterEmail: 'Por favor, insira seu email',
        PleaseEnterEmailFormat: 'Por favor, insira o formato de email correto',
        day: 'dia',
        also: 'também',
        downTime: 'Data de lançamento',
        changePwd: 'Mudar senha',
        RechargeNow: 'Recarregar agora',
        PlayGame: 'Registro bem-sucedido! Jogue um jogo~',
        serviceTips: 'Por favor, concorde primeiro com os termos de serviço',
        SuccessfullyClaimed: 'Reivindicado com sucesso',
        SuccessfullyClaimedTips1: '',
        SuccessfullyClaimedTips2: ' bônus serão distribuídos para a conta',

        Account: 'Conta',
        PleaseEnterAccount: 'Por favor, insira a conta',
        AccountFormat: 'Erro no formato da conta, 6-16 caracteres, suporta letras/números',
        PhoneFormat: 'O formato do número de telefone que você inseriu está incorreto',
        PleaseEnterPassword: 'Por favor, insira a senha',
        PasswordFormat: '8-16 caracteres. Deve incluir pelo menos letras minúsculas/letras maiúsculas/números',
        ConfirmPassword: 'Confirmar senha',
        ConfirmPwdAgain: 'Confirme a senha novamente',
        InvitationCode: 'Código de convite',
        PleaseEnteryourInvitationCode: 'Por favor, insira seu código de convite',
        OverAgeAgree: 'Eu tenho mais de 18 anos, li e aceito',
        PleaseAgreeOverAge: 'Primeiro, verifique o acordo do usuário',
        RememberYouraccountPassword: 'Lembre-se da sua senha de conta',
        SelectCountry: 'Idioma',

        Reminder: 'Lembrete',
        WantLogOutAccount: 'Você deseja sair da sua conta?',
        ConfirmExit: 'Confirmar saída',
        Cancel: 'Cancelar',

        Strength: 'Força',
        sixteenDigits: '8-16 bits',
        UpperCaseletters: 'Letras maiúsculas',
        LowerCaseLetters: 'Letras minúsculas',
        Numbers: 'Números',

        UNLOCK: 'DESBLOQUEAR',
        ENDLESSBENEFITS: 'BENEFÍCIOS INFINITOS',
        loginTips1: 'Bônus generosos estão prontos! Junte-se a nós, encontre seus próprios tesouros',
        loginTips2: 'Evento VIP exclusivo: Aposte e obtenha oportunidades',
        loginTips3: 'Envelope vermelho, pagamento regular diário',
        loginTips4: 'Os benefícios de convidar seus amigos para obter baús de tesouro.',
        loginTips5: 'Os benefícios de fazer login todos os dias',
        loginTips6: 'Quando você fizer seu primeiro depósito, poderá receber um bônus de até 20%.',
        loginTips7: 'Atualize para VIP para receber bônus',
        loginTips8: 'Bem-vindo a ',
        loginTips9: 'TRAZER a você uma experiência de jogo sem precedentes'
    },
    logout: {
        SignOut: 'Sair',
        SignOutTips: 'Explore um mundo repleto de recompensas e jogos emocionantes!'
    },
    signin: {
        signInTitle: 'Bônus de Registro Diário',
        signInDays: 'Registrado continuamente por ',
        days: ' dias',
        day: 'DIA',
        claimed: 'Reivindicado ',
        condition1: 'Depósito necessário',
        condition2: 'Apostas necessárias',
        eventDetails: 'Detalhes do Evento',
        checkin: 'Check-in',
        CheckIn: 'REGISTRAR',
        Today: 'Hoje',
        Uncompleted: 'NÃO COMPLETO',
        Completed: 'COMPLETO',
        Conditions: 'Condições',
        Rules: 'Regras'
    },
    share: {
        panel: 'Painel',
        FAQ: 'FAQ',
        TUTORIAL: 'TUTORIAL',
        TotalRewards: 'Recompensas Totais',
        TotalFriends: 'Total de Amigos',
        inviteFriend: 'CONVIDE AMIGOS PARA GANHAR',
        signUp: 'Inscreva-se e ganhe',
        Commission: 'Comissão',
        InviteVia: 'Convide via:',
        copy: 'Copiar',
        link: 'Link',
        code: 'Código',
        or: 'OU',
        Rewards: 'Recompensas',
        title1: 'COMISSÃO',
        tip1: 'Ganhe até',
        tip2: 'comissão quando seu amigo apostar.',
        title2: 'CASHBACK DE DEPÓSITO',
        tip3: 'Ganhe',
        tip4: 'quando seu amigo depositar.',
        Details: 'Detalhes',
        UncollectedCommissions: 'Comissões não coletadas',
        ExclusiveCommission: 'Comissão Exclusiva',
        DepositCashback: 'Cashback de depósito',
        Claim: 'Reivindicar',
        TodayTeamData: 'Dados da equipe de hoje',
        TotalTeamData: 'Dados totais da equipe',
        SignUp: 'Inscreva-se',
        FirstDeposit: 'Primeiro Depósito',
        ValidBet: 'Aposta válida',
        LiveRewards: 'Recompensas ao vivo',
        SendTotalCommission: 'Enviar comissão total',
        rules: 'Regras',
        ruleInfo: 'Você pode ganhar dinheiro referindo amigos e ganhar recompensas de comissão!',
        Recommended: 'Recomendado',
        Month: 'Mês',
        UnlimitedSubordinates: 'Desenvolvimento ilimitado de subordinados',
        planTitle: 'SAIBA MAIS SOBRE NOSSO PROGRAMA DE AFILIADOS',
        planInfo: 'Se você tem um grande público e seguidores. Temos condições especiais para você personalizar seu programa de referência!',
        all: 'Todos',
        Direct: 'Direto',
        Indirect: 'Indireto',
        TodayTeamIntro1: 'Inscrição: Número de usuários que se inscreveram hoje',
        TodayTeamIntro2: 'Primeiro Depósito: Número de usuários que fizeram o primeiro depósito hoje',
        TodayTeamIntro3: 'Apostas Válidas: Apostas válidas para a equipe de hoje',
        TotalTeamIntro1: 'Inscrição: Total de inscrições da equipe',
        TotalTeamIntro2: 'Primeiro Depósito: Total de depositantes pela primeira vez',
        TotalTeamIntro3: 'Apostas Válidas: O total de apostas efetivas da equipe',
        calculationRules: 'Regras de cálculo de comissão',
        rewardRules: 'Regras de recompensa de comissão',
        ExtendRewardsBouns: 'Bônus de recompensas estendidas',
        GameBettingRewardsBouns: 'Bônus de recompensas de apostas em jogos',
        RechargeRewardsBouns: 'Bônus de recompensas de recarga',
        Tier: 'Nível',
        intro1: 'Inscrição: Número de usuários que se inscreveram hoje',
        intro2: 'Primeiro Depósito: Número de usuários que fizeram o primeiro depósito hoje',
        intro3: 'Apostas Válidas: Apostas válidas para a equipe de hoje',
        intro4: 'Inscrição: Total de inscrições da equipe',
        intro5: 'Primeiro Depósito: Total de depositantes pela primeira vez',
        intro6: 'Apostas Válidas: O total de apostas efetivas da equipe',
        rule1: 'Toda vez que seu jogador recomendado fizer uma aposta, ganhar ou perder, você receberá uma porcentagem de comissão diferente.'
    },
    deposit: {
        PaymentMethodsRregion: 'Métodos de pagamento para a região',
        DepositMethods: 'Métodos de depósito',
        Deposit: 'Depositar',
        FirstDeposit: 'Primeiro Depósito',
        Details: 'Detalhes',
        Amount: 'Quantidade',
        Extra: 'Extra',
        noBonus: 'sem bônus',
        PleaseEnterDeposit: 'Por favor, insira o valor do depósito',
        isMinRechargeAmtTips: 'O valor do recarregamento deve ser maior que o valor mínimo de recarregamento',
        isMaxRechargeAmtTips: 'O valor do recarregamento deve ser menor que o valor máximo de recarregamento',
        showBonusTips: 'Não participe de promoções de depósito',
        rewardPercentageTxt1_1: 'Acima de',
        rewardPercentageTxt1_2: 'mais',
        rewardPercentageTxt1_3: '%',
        rewardPercentageTxt2_1: 'Primeira Preenchimento Diário ',
        rewardPercentageTxt2_2: '+',
        rewardPercentageTxt2_3: '%',
        rewardPercentageTxt3_1: 'Primeira Preenchimento de Registro ',
        rewardPercentageTxt3_2: '+',
        rewardPercentageTxt3_3: '%',
        Change: 'Mudar',
        DepositBonus: 'Bônus de Depósito',
        DepositAmount: 'Valor do Depósito',
        Bonus: 'Bônus',
        DepositTips: 'Os depósitos na plataforma oferecem recompensas generosas, com um valor especificado de dinheiro depositado a cada vez para receber um bônus.',
        howTitle: 'Como reivindicar o bônus de depósito?',
        Step: 'Passo',
        Step2Title: 'Reivindicar bônus de depósito',
        howTips1: 'Vá para o depósito. Escolha seu método de depósito preferido para fazer um depósito.',
        howTips2: 'Após a conclusão do depósito, você receberá o bônus correspondente com base no valor do depósito e na proporção do bônus.',
        TransferDeposit: 'Transferir Depósito',
        OnlineDeposit: 'Depósito Online',
        ExchangeRate: 'Taxa de câmbio',
        Tutorial: 'Tutorial de depósito em USDT'
    },
    DepositDetails: {
        DepositDetails: 'Detalhes do Depósito',
        WaitingForPayment: 'Aguardando pagamento.',
        countDownTxt1: 'Por favor',
        countDownTxt2: 'complete o depósito',
        ExchangeRate: 'Taxa de câmbio',
        DepositMethod: 'Método de Depósito',
        Currency: 'Moeda',
        MainnetProtocol: 'Protocolo Mainnet (o canal de rede, muito importante)',
        PaymentAddress: 'Endereço de Pagamento',
        CreationTime: 'Hora de Criação',
        OrderNumber: 'Número do Pedido',
        TransactionRemark: 'Observação da transação',
        cashAddress: 'endereço de caixa',
        DepositTips1: '1. Por favor, certifique-se do valor correto após deduzir a taxa de retirada (o valor recebido correto) para completar a transação; caso contrário, o depósito não será bem-sucedido.',
        DepositTips2: '2. Confirme se o protocolo mainnet que você está transferindo/depositando corresponde ao protocolo mainnet que você selecionou. Caso contrário, o depósito/transferência não será bem-sucedido ou será reembolsado.',
        DepositTips3: '3. Suporta apenas USDT. Por favor, não transfira outras moedas para este endereço.',
        cancelBtn: 'cancelar um pedido',
        TransferredBtn: 'Eu transferi',
        ContinueBtn: 'Continuar a Depositar',
        hashAddress: 'Endereço Hash',
        pleasetEnterHashAddress: 'por favor insira o endereço hash',
        cancelOrderTips: 'Você deseja cancelar este pedido de pagamento?',
        waitPayTips: 'Atualmente há pedidos não pagos, você gostaria de prosseguir com o pagamento?'
    },
    Withdraw: {
        Withdraw: 'Retirar',
        BalanceWithdraw: 'Retirada de Saldo',
        BonusWithdraw: 'Retirada de Bônus',
        WithidrawLimitPerday: 'Limite de retirada por dia',
        WithdrawTimePerday: 'Hora de retirada por dia',
        WithdrawableBalance: 'Saldo Retirável',
        WithdrawableBonus: 'Bônus restante',
        WithdrawAmount: 'Valor da retirada',
        ratetxt1: 'Eu também preciso apostar',
        ratetxt2: 'para retirar',
        ratetxt3: ' ainda requerem uma aposta de ',
        ratetxt4: ' para retirar',
        ChooseYourBankcard: 'Escolha seu cartão bancário',
        AddABankCardFirst: 'Adicione um cartão bancário primeiro',
        WithdrawalAmount: 'Valor da retirada',
        disabledWithdraw: 'A função de retirada da conta atual foi desativada. Por favor, entre em contato com o atendimento ao cliente!',
        PleaseEnterMiniWithdrawPrice: 'O valor da retirada não pode ser menor que o valor mínimo de retirada',
        PleaseEnterWithdraw: 'Por favor, insira a retirada',
        PleaseEnterInteger: 'Por favor, insira um número inteiro para o valor da retirada',
        PleaseChooseBank: 'Por favor, insira primeiro as informações do cartão bancário',
        VerifyIdentity: 'Verificar identidade',
        notInTime1: 'Hora de retirada disponível:',
        notInTime2: 'Hora de liquidação do sistema:',
        notInTime3: 'Após o início da retirada, o valor chegará à conta dentro de 24 horas após o início da retirada. Se você tiver alguma dúvida, entre em contato com nosso suporte ao cliente.',
        All: 'Todos',
        OnlineWithdraw: 'Retirada Online',
        TransferWithdraw: 'Retirada por Transferência'
    },
    bankset: {
        BankCardDetails: 'Detalhes do Cartão Bancário',
        Type: 'Tipo',
        AccountName: 'Nome da conta',
        NameOfTheAccountHolder: 'Nome do titular da conta',
        PIXAccount: 'Conta PIX',
        InputPIXAccount: 'Insira a conta PIX',
        CPFAccount: 'Conta CPF',
        PleaseEnterCPF: 'Por favor, insira o CPF',
        WithdrawPassword: 'Senha de Retirada',
        InputWithdrawalPassword: 'Digite a senha de retirada',
        ConfirmWithdrawalPassword: 'Confirmar senha de retirada',
        PleaseEnterWithdrawPassword: 'Por favor, insira a senha de retirada',
        InputConfirmWithdrawalPassword: 'Insira a senha de retirada de confirmação',
        ChooseCertificateType: 'Escolha o tipo de certificado',
        InputAccountName: 'Insira o nome da conta',
        InputInconsistentWithdrawPassword: 'Entrada de senha de retirada inconsistente',
        PayeeAccount: 'número da conta beneficiária',
        BankName: 'Nome do banco',
        ChooseBank: 'Escolha um banco',
        PleaseSelectBank: 'Por favor, selecione um banco',
        AccountPhone: 'Número de telefone',
        EnterPhoneNumber: 'insira o número de telefone',
        PleaseEnterPhoneNumber: 'Por favor, insira seu número de telefone',
        email: 'E-mail',
        PleaseEnterEmail: 'Por favor, insira seu endereço de e-mail',
        EmailFormat: 'Formato de e-mail incorreto',
        InputPayeeAccount: 'Por favor, insira o número da conta recebedora',
        PleaseSelectChannelName: 'Por favor, selecione o nome do canal',
        Save: 'Salvar'
    },
    Notification: {
        Notification: 'Notificação',
        news: 'Notícias',
        nomore: 'Sem mais'
    },
    bonusCenter: {
        BonusDetails: 'Detalhes do Bônus',
        BonusCategories: 'Categorias de Bônus',
        WeeklyBonus: 'Bônus Semanal',
        MonthlyBonus: 'Bônus Mensal',
        LevelupBonus: 'Bônus de Nível',
        BonusRain: 'Pacote Vermelho',
        DepositBonus: 'Bônus de Depósito',
        SpecialBonus: 'Bônus Especial',
        BonusTransactions: 'Transações de Bônus',
        BonusType: 'Tipo de Bônus',
        ClaimedAmount: 'Valor Reivindicado',
        Time: 'Hora',
        OopsNodatayet: 'Ops! Nenhum dado ainda!',
        TotalBonusClaime: 'Total de Bônus Reivindicados',
        TotalGeneralBonus: 'Bônus de atividade',
        TotalVIPBonus: 'Total de Bônus VIP',
        rechargeBonus: 'Bônus de recarga',
        Details: 'Detalhes',
        TotalBonus: 'Total de Bônus:',
        Rules: 'Regras',
        Bonusends: 'Bônus termina:',
        DepositNow: 'Depositar agora',
        Generalbonus: 'Bônus geral',
        Todayevents: 'Eventos de hoje',
        Nextrain: 'Próxima vez',
        CheckIn: 'Check In',
        Counts: 'Contagens',
        Deposit: 'Depósito',
        VIPBonus: 'Bônus VIP',
        Levelupbonus: 'Bônus de nível',
        XPtoVIP: 'XP para VIP',
        UpgradeBonus: 'Bônus de atualização',
        NextLevelBonus: 'Bônus do próximo nível',
        ViewVIPClub: 'Ver Clube VIP',
        Wager: 'Aposta',
        VIP: 'VIP',
        Unlockendless: 'Desbloquear benefícios infinitos',
        Generousbonuses: 'Bônus generosos estão prontos! Junte-se a nós. Encontre seus próprios tesouros.',
        JoinNow: 'Junte-se agora',
        Upgradebonuses: 'Atualize VIP para obter bônus',
        Invitefriendss: 'Convide amigos para jogar e ganhe bônus',
        Completequest: 'Complete a missão para ganhar bônus',
        Winbigwith: 'Ganhe muito com nossos bônus semanais e mensais!',
        Discover: 'Descubra bônus extraordinários',
        numerousbonuses: 'Oferecemos inúmeros bônus a cada usuário. Há novas recompensas todos os dias e todas as semanas.',
        Signbonus: 'Inscreva-se e ganhe bônus',
        LevelupVIP: 'Suba de nível VIP, mais recompensas!',
        VIPstatusmeans: 'O status VIP significa mais valor aqui. Você pode rapidamente construir riqueza e criar uma base financeira sólida desbloqueando seus benefícios.',
        Whatget: 'O que os VIPs recebem?',
        Saygoodbye: 'Diga adeus ao jogo comum, olá ao luxo puro.',
        RedeemBonusCode: 'Resgatar Código de Bônus',
        RedeemCode: 'Resgatar Código',
        AllBonuses: 'Todos os bônus',
        eventManage: 'Bônus de atividade',
        VIPCycleReward: 'Recompensa de nível VIP',
        Extend: 'Bônus de promoção',
        reliefMoneyEvent: 'Atividade de resgate de ouro',
        singInEvent: 'Atividade de registro',
        luckyWheelEvent: 'Atividade da Roda da Sorte',
        redPacletEvent: 'Atividade de pacote vermelho',
        gameBettingEvent: 'Atividade de apostas em jogos',
        pinduoduoEvent: 'Atividades de convite',
        rankingListEvent: 'Atividade de classificação',
        registerRewardsEvent: 'Atividade de registro',
        VIPWeeklyReward: 'Recompensas Semanais VIP',
        VIPMonthlyReward: 'Recompensas Mensais VIP',
        VIPLevelUpReward: 'Recompensa de nível VIP',
        Redeemyour: 'Resgate seu código de bônus abaixo.',
        Redeem: 'Resgatar',
        Unlocked: 'Desbloqueado',
        RechargeAmount: 'Valor de recarga'
    },
    lossSupport: {
        title: 'Suporte a Perdas',
        lossSupport: 'Suporte',
        yesterdayLoss: 'Perdas de ontem',
        todayBonus: 'Bônus de Suporte de Hoje',
        loseMoney: 'Valor da perda',
        extraprizes: 'Prêmios extras',
        amountDamage: 'quantidade de dano',
        AmountReward: 'Valor da recompensa',
        EventIntro: 'Instruções do Evento:'
    },
    rebate: {
        title: 'Rebates em apostas',
        BettingVolume: 'Volume de apostas',
        RewardAmount: 'Valor da recompensa',
        EventIntro: 'Instruções do Evento:'
    },
    downApp: {
        downAppSuccTips: 'Você instalou o aplicativo com sucesso.',
        downAppSuccTips2: 'Você já instalou o aplicativo. Ou clique no ícone do botão de download no canto superior direito do endereço URL do navegador para reinstalar.',
        SaveVersionDesktop: 'Salvar versão da web na área de trabalho',
        SaveVersionTips: 'Clique para baixar rapidamente o aplicativo para sua área de trabalho',
        QuickVersion: 'Versão rápida',
        NormalVersion: 'Versão normal',
        download: 'Baixar',
        NotInDevice: 'Não compatível com este dispositivo',
        Games: 'Jogos',
        Apps: 'Aplicativos',
        Movies: 'Filmes',
        Books: 'Livros',
        Kids: 'Crianças',
        ProductMadness: 'Loucuras de Produtos',
        InAppPurchases: 'Compras dentro do aplicativo',
        reviews: 'avaliações',
        Downloads: 'Downloads',
        Install: 'Instalar',
        Share: 'Compartilhar',
        AddToWishlist: 'Adicionar à lista de desejos',
        appAvailableDevice: 'Este aplicativo está disponível para seu dispositivo',
        AboutGame: 'Sobre este jogo',
        comment1: 'Experimente a emoção das cartas e a empolgação do jogo de apostas de alto risco em gd-goatbet! Junte-se a uma comunidade vibrante de entusiastas de cartas neste jogo de cartas imersivo e social. Jogue Teen Patti em vários modos, participe de torneios emocionantes e suba para se tornar o ultimate gd-goatbet. Com sua jogabilidade autêntica, recursos sociais e espírito competitivo, gd-goatbet oferece uma experiência de jogo de cartas cativante que fará você voltar sempre.',
        comment2: '1. Experiência Autêntica de Teen Patti: Mergulhe na experiência autêntica e tradicional do jogo de cartas Teen Patti, com regras realistas e jogabilidade envolvente.',
        comment3: '2. Modos de Jogo Diversos: Explore uma variedade de modos de jogo, desde o Teen Patti clássico até variações inovadoras, proporcionando uma experiência de jogo dinâmica e divertida.',
        comment4: '3. Jogo de Cartas Social: Conecte-se com amigos, junte-se a clubes e participe de partidas multiplayer, promovendo um ambiente de jogo social e interativo.',
        DataSafety: 'Segurança de Dados',
        comment5: 'A segurança começa com a compreensão de como os desenvolvedores coletam e compartilham seus dados. As práticas de privacidade e segurança de dados podem variar com base no seu uso, região e idade. O desenvolvedor forneceu essas informações e pode atualizá-las ao longo do tempo.',
        comment6: 'Este aplicativo pode compartilhar esses tipos de dados com terceiros',
        comment7: 'Localização, Informações pessoais e 6 outros',
        comment8: 'Este aplicativo pode coletar esses tipos de dados',
        comment9: 'Localização, Informações pessoais e 6',
        comment10: 'Os dados são criptografados em trânsito',
        comment11: 'Você pode solicitar que os dados sejam excluídos',
        SeeDetails: 'Ver detalhes',
        RatingsAndReviews: 'Avaliações e comentários',
        Phone: 'Telefone',
        Tablet: 'Tablet',
        October: 'Outubro',
        comment12: 'Ótima escolha de jogos populares que também estão nos cassinos ao vivo, e muita diversão para jogar! Assistir anúncios sozinhos pode sustentar o jogo, mas pode ser agonizantemente lento às vezes. Esteja preparado para fazer compras ou ter muita paciência. Às vezes, você tem que assistir a um anúncio de 30 segundos a um minuto entre algumas mãos de jogo, e isso pode acontecer por várias mãos. Em alguns jogos, você não pode completar os requisitos de bônus a menos que compre dinheiro para jogar. AINDA, meu favorito de todos os aplicativos de cassino para jogar!',
        comment13: '3.394 pessoas acharam esta avaliação útil',
        comment14: 'Você achou isso útil?',
        Yes: 'Sim',
        No: 'Não',
        September: 'Setembro',
        comment15: 'Eu realmente gosto deste jogo. Tem uma variedade de slots diferentes para jogar. Posso ganhar moedas extras enquanto jogo. Não há muitos anúncios para assistir. A quantidade de moedas ao comprar é menor do que em outros jogos, mas devido à qualidade deste jogo, vale a pena na minha opinião. Eu baixei e depois excluí outros aplicativos depois de jogá-los.',
        comment16: '1.110 pessoas acharam esta avaliação útil',
        comment17: 'Você achou isso útil?',
        WhatNew: 'O que há de novo',
        comment18: 'Obtenha nossos NOVOS jogos em nossa última atualização!',
        comment19: 'Para a melhor experiência e mais DIVERSÃO, eliminamos bugs e otimizamos seu jogo.',
        AppSupport: 'Suporte do aplicativo',
        SimilarGames: 'Jogos Similares',
        LightningLinkCasinoSlots: 'Slots de Cassino Lightning Link',
        QuickHitCasinoSlotGames: 'Jogos de Slots de Cassino Quick Hit',
        SciPlay: 'SciPlay',
        LuckyTimeSlots: 'Slots de Tempo de Sorte',
        DGNGames: 'Jogos DGN',
        CashTornadoSlotsCasino: 'Slots de Cassino Cash Tornado™',
        ZerooGravityGames: 'Jogos Zeroo Gravity',
        GoldFishCasinoSlot: 'Slot de Cassino Gold Fish',
        FlagInappropriate: 'Marcar como inadequado',
        GooglePlay: 'Jogar',
        PlayPass: 'Play Pass',
        PlayPoints: 'Pontos de Jogo',
        GiftCards: 'Cartões-presente',
        Redeem: 'Resgatar',
        RefundPolicy: 'Política de reembolso',
        KidsFamily: 'Família de crianças',
        ParentGuide: 'Guia para pais',
        FamilySharing: 'Compartilhamento familiar',
        TermsService: 'Termos de Serviço',
        Privacidade: 'Privacidade',
        SobreGooglePlay: 'Sobre o Play',
        Desenvolvedores: 'Desenvolvedores',
        GoogleStore: 'Loja',
        UnitedStates: 'Estados Unidos (Inglês)',

        AppStore: 'App Store',
        DesignedIPhone: 'Projetado para iPhone',
        get: 'obter',
        iPhoneScreenshots: 'Capturas de tela do iPhone',
        AppPrivacy: 'Privacidade do aplicativo',
        intro1: 'O desenvolvedor, ',
        intro2: ', indicou que as práticas de privacidade do aplicativo podem incluir o manuseio de dados conforme descrito abaixo. Para mais informações, consulte a ',
        intro3: 'política de privacidade do desenvolvedor',
        intro4: 'Dados Não Coletados',
        intro5: 'O desenvolvedor não coleta nenhum dado deste aplicativo.',
        intro6: 'As práticas de privacidade podem variar, por exemplo, com base nas funcionalidades que você usa ou na sua idade.',
        intro7: 'Informação',
        Seller: 'Vendedor',
        Size: 'Tamanho',
        Category: 'Categoria',
        Compatibility: 'Compatibilidade',
        iPhone: 'iPhone',
        support1: 'Requer iOS 10.0 ou posterior.',
        iPodTouch: 'iPod touch',
        support2: 'Requer iOS 10.0 ou posterior.',
        Mac: 'Mac',
        support3: 'Requer macOS 11.0 ou posterior e um Mac com chip Apple M1 ou posterior.',
        Languages: 'Idiomas',
        EnglishSimplifiedChinese: 'Inglês, Chinês Simplificado',
        AgeRating: 'Classificação de Idade',
        UnrestrictedWebAccess: 'Acesso à Web Sem Restrições',
        InfrequentMildHorrorFearThemes: 'Temas de Horror/Medo Leves/Infrequentes',
        Copyright: 'Direitos Autorais',
        Price: 'Preço',
        Free: 'Gratuito',
        PrivacyPolicy: 'Política de Privacidade',
        Supports: 'Suporta',
        GameCenter: 'Game Center',
        GameCenterTips: 'Desafie amigos e verifique tabelas de classificação e conquistas.',
        Morewaysshop: 'Mais maneiras de comprar',
        FindAppleStore: 'Encontre uma Apple Store',
        or: 'ou',
        otherRetailer: 'outro varejista',
        nearYou: 'perto de você.',
        OrCall: 'Ou ligue para 1-800-MY-APPLE.',
        ChooseYourCountryRegion: 'Escolha seu país ou região',
        TermsUse: 'Termos de Uso',
        SalesRefunds: 'Vendas e Reembolsos',
        Legal: 'Legal',
        SiteMap: 'Mapa do Site',
        ClickButtonToDownload: 'Baixe o aplicativo para retiradas mais seguras',
        ClickButtonToDownload2: 'Baixe o aplicativo para jogar'
    },
    event: {
        Detail: 'Detalhe',
        greatBonus: 'Ótimo bônus para ',
        everyDeposit: 'Cada Depósito',
        firstDeposit: 'Primeiro Depósito',
        secondDeposit: 'Segundo Depósito',
        thirdDeposit: 'Terceiro Depósito',
        upTo: 'Até',
        allPlayers: 'Disponível para todos os jogadores ',
        before: 'Antes',
        Bonus: 'Bônus',
        Deposit: 'Depósito'
    },
    person: {
        Bets: 'Apostas',
        Balance: 'Saldo',
        Bonus: 'Bônus',
        Transaction: 'Transação',
        History: 'Histórico',
        Welcome: 'Bem-vindo a ',
        signIn: 'Por favor, inscreva-se ou faça login primeiro',
        invite: 'Convide amigos',
        redPacket: 'Pacote Vermelho',
        Notification: 'Notificação',
        Promotion: 'Promoção',
        VIPClub: 'Clube VIP',
        LiveChat: 'Chat ao Vivo',
        AccountSettings: 'Configurações da conta',
        Install: 'Instalar'
    },
    Vip: {
        VIPSupport: 'Suporte VIP',
        LevelBenefits: 'Nível VIP e Benefícios',
        Deposits: 'Depósitos',
        Bets: 'Apostas',
        or: 'ou',
        to: 'para',
        joinClub: 'Junte-se ao Clube VIP. Desbloqueie altos cashback. Todos os tipos de presentes requintados. Isso vai te surpreender!',
        levelUp: 'Suba de nível para VIP',
        Steps: 'Passos',
        Benefits: 'Benefícios',
        benefits1: 'Bônus de nível',
        benefits2: 'Bônus semanal',
        benefits3: 'Bônus mensal',
        info1: 'Suba de nível VIP para obter bônus!',
        info2: 'Reivindique bônus gratuitos toda semana.',
        info3: 'Reivindique bônus gratuitos todo mês.',
        More: 'Mais',
        promotionTip: 'Por favor, veja nossas promoções para mais detalhes.',
        Promotions: 'Promoções',
        FAQ: 'FAQ',
        question1: '1. O que é "Bônus de Subida de Nível"?',
        answer1: 'Um "bônus de subida de nível" é um benefício VIP onde as recompensas aumentam à medida que você avança para níveis VIP mais altos.',
        question2: '2. Benefícios de atualizar para VIP',
        answer2: 'Atualizar seu VIP pode obter um valor de retirada mais alto e uma taxa de retirada mais baixa!',
        LevelUpBonus: 'Bônus de subida de nível',
        WeeklyBonus: 'Bônus semanal',
        MonthlyBonus: 'Bônus Mensal',
        Level: 'Nível',
        RequiredDeposit: 'Depósito Necessário',
        RequiredBet: 'Aposta Necessária',
        Get: 'Obter',
        UpgradeBonus: 'Bônus de atualização',
        vipRewards: 'Inscreva-se e obtenha recompensas VIP exclusivas!',
        earnPoints: 'Aposte e ganhe pontos!',
        levelUpBenifits: 'Desbloqueie recompensas, suba de nível seus benefícios!',
        Step1: 'Passo 1',
        Step2: 'Passo 2',
        Step3: 'Passo 3'
    },
    MyProfile: {
        Profile: 'Perfil',
        ID: 'ID',
        Join: 'Juntar-se',
        TotalBet: 'Aposta Total',
        TotalBonus: 'Bônus Total',
        FavoriteGames: 'Jogos Favoritos'
    },
    Transaction: {
        Transaction: 'Transação',
        TotalAmount: 'Valor Total',
        DepositValue: 'Valor do Depósito',
        Bonus: 'Bônus',
        Received: 'Recebido',
        WithdrawBonus: 'Retirar bônus',
        Rate: 'Taxa',
        Today: 'Hoje',
        Yesterday: 'Ontem',
        LastThreeDays: 'Últimos 3 dias',
        LastSevenDays: 'Últimos 7 dias',
        LastFifteenDays: 'Últimos 15 dias',
        LastThiryDays: 'Últimos 30 dias',
        AllState: 'Todos os Estados',
        Processing: 'Processando',
        Successful: 'Bem-sucedido',
        Failed: 'Falhou',
        WithdrawFail: 'Falha na Retirada',
        Withdrawing: 'Retirando',
        WithdrawSuccess: 'Retirada Bem-sucedida',
        PaySuccess: 'Pagamento Bem-sucedido',
        Paying: 'Pagando',
        Fail: 'Falha',
        WaitPay: 'Aguardando Pagamento',
        Cancel: 'Cancelar'
    },
    BetsHistory: {
        BetsHistory: 'Histórico de Apostas'
    },
    Setting: {
        Setting: 'Configuração',
        LiveChat: 'Chat ao Vivo ',
        AccountID: 'ID da Conta',
        PhoneNumber: 'Número de Telefone',
        Password: 'Senha',
        Change: 'Mudar',
        oldPasswordTips: 'digite a senha original',
        PleaseEnterYourOriginalPassword: 'Por favor, insira sua senha original',
        passwordTips: 'Senha (6-16)',
        PleaseEnterYourNewPassword: 'Por favor, insira sua nova senha',
        passwordTips2: 'Digite a senha novamente',
        PleaseEnterYourNewPasswordSure: 'Por favor, insira sua nova senha novamente',
        changePwd: 'Mudar senha'
    },
    Wallet: {
        Wallet: 'Carteira',
        TotalBalance: 'Saldo Total',
        Cash: 'Dinheiro',
        safeFunds: 'Os fundos são muito seguros',
        tip1: 'é uma plataforma de jogos de azar online formal.',
        tip2: 'Aceitamos supervisão governamental, portanto, temos maior credibilidade e melhor segurança.',
        tip3: 'Por favor, aposte com tranquilidade, seus fundos serão garantidos no mais alto nível do início ao fim.'
    },
    RedPacket: {
        RedPacket: 'Pacote Vermelho',
        NextEvent: 'Próximo Evento',
        Participate: 'Participar'
    },
    turntable: {
        lottery: 'Loteria',
        title: 'Roda da Fortuna Jackpot',
        totalTips1: 'Pontos de sorte atuais ',
        totalTips2: 'Você ainda precisa apostar ',
        totalTips3: ' para obter ',
        totalTips4: ' Pontos de Sorte',
        Silver: 'Prata',
        Gold: 'Ouro',
        Diamond: 'Diamante',
        tab1: 'Relatório',
        tab2: 'Minha Referência',
        JustRemoved: 'acabou de retirar',
        drawTitle1: 'Parabéns',
        drawTitle2: 'infelizmente',
        drawContent1: 'obter',
        drawContent2: 'Nenhuma recompensa recebida',
        cashOut: 'Sacar',
        InviteFriends: 'Convide amigos para ganhar dinheiro',
        nextFreeSpin: 'Próxima Rodada Grátis:',
        targetAmount: 'Valor Alvo:',
        withdrawSuccessfully: 'Retirada bem-sucedida',
        downTimeTips1: 'Após',
        downTimeTips2: 'segundos,',
        downTimeTips3: 'os bônus acumulados e as vezes da loteria serão limpos'
    },
    winRankList: {
        RankingList: 'Lista de Classificação',
        Ranking: 'Classificação',
        Rules: 'Regras',
        MyRewards: 'Minhas recompensas',
        ActivityAward: 'Prêmio da Atividade:',
        Rank: 'Classificação',
        Daily: 'Diário',
        Weekly: 'Semanal',
        Monthly: 'Mensal',
        WeeklyList: 'Lista Semanal',
        MonthlyList: 'Lista Mensal',
        TotalBets: 'Total de Apostas',
        Reward: 'Recompensa:',
        NoRank: 'Sem classificação',
        MyBets: 'Minhas Apostas',
        RanksLeft: 'Classificações restantes',
        MyReward: 'Minha Recompensa',
        History: 'Histórico',
        LastDay: 'Último Dia',
        LastWeek: 'Última Semana',
        LastMonth: 'Último Mês',
        Rewards: 'Recompensas',
        PhoneNo: 'Número de Telefone'
    },
    footer: {
        Promo: 'Promoção',
        VIPClub: 'Clube VIP',
        Promotions: 'Promoções',
        ReferAndEarn: 'Indique e Ganhe',
        Bonus: 'Bônus',
        AboutUs: 'Sobre Nós',
        About: 'Sobre',
        DataProtectionPolicy: 'Política de Proteção de Dados',
        BusinessContinuityPolicy: 'Política de Continuidade de Negócios',
        Help: 'Ajuda',
        PrivacyPolicy: 'Política de Privacidade',
        ServiceTerms: 'Termos de Serviço',
        GamblingAddictionWarnings: 'Avisos de Dependência de Jogo',
        GamblingTreatmentCenters: 'Centros de Tratamento de Jogo',
        ResponsibleGambling: 'Jogo Responsável',
        ContactUs: 'Contate-Nos',
        JoinOurGroup: 'Junte-se ao nosso grupo',
        ContactViaTelegram: 'Contate via Telegram',
        OnlineServer: 'Servidor Online',
        JoinourCommunity: 'Junte-se à nossa Comunidade',
        tips1: '1. Este produto é para uso de usuários com mais de 18 anos e é destinado apenas para fins de entretenimento.',
        tips2: '2. Este jogo contém compras dentro do aplicativo.',
        tips3: '3. O fato de um jogador jogar ou ganhar em um jogo de azar social não significa que ele ganhará em apostas de dinheiro real e jogos relacionados no futuro.'
    },
    treasureChest: {
        title: 'Recomende amigos para abrir o baú do tesouro',
        save: 'Clique para Salvar',
        ReferralLink: 'Link de Referência',
        directSubordinates: 'Subordinados diretos',
        ReferralCode: 'Código de Referência',
        chestInfoTips1: 'O que é um subordinado eficaz? (atende a todos os requisitos)',
        chestInfoTips2: 'Subordinados completaram um total de ',
        chestInfoTips3: ' primeiros reabastecimentos',
        chestInfoTips4: 'Subordinados acumulam apostas de ',
        chestInfoTips5: ' ou mais',
        People: 'Pessoas',
        People2: 'Pessoas',
        People3: 'Pessoas',
        commissionAccount: 'Conta de Comissão',
        todayCommittee: 'O comitê liberou hoje',
        historyCommittee: 'Comissões históricas publicadas',
        obtain: 'obter',
        inviteTitle: 'Obtido ao convidar subordinados válidos',
        highestRebate: 'Maior reembolso',
        commissionBalance: 'Saldo de Comissão',
        totalCommissionIncome: 'Renda total de comissão',
        EstimatedCommissionToday: 'Comissão estimada hoje',
        YesterdayCommittee: 'Comitê de ontem',
        register: 'Registrar',
        firstChargeMark: 'Marca de primeiro carregamento',
        firstFee: 'Primeira taxa',
        chargeAmount: 'Valor do carregamento',
        totalHistoricalData: 'Dados históricos totais',
        firstLevelSubordinateData: 'Dados de subordinados de primeiro nível',
        secondarySubordinateData: 'Dados de subordinados de segundo nível',
        thirdLevelSubordinateData: 'Dados de subordinados de terceiro nível',
        rewardRatio: 'Taxa de recompensa',
        collectRebates: 'Bônus de recompensas de recarga',
        teamCollection: 'Recarga',
        firstLevelCommission: 'Comissão de primeiro nível:',
        secondLevelCommission: 'Comissão de segundo nível:',
        thirdLevelCommission: 'Comissão de terceiro nível:',
        firstLevelBetting: 'Apostas de primeiro nível:',
        secondLevelBetting: 'Apostas de segundo nível:',
        thirdLevelBetting: 'Apostas de terceiro nível:'
    },
    Pending: {
        Pending: 'Pendente',
        Hour: 'Hora',
        Type: 'Tipo',
        Bonus: 'Bônus',
        Limit: 'Limite',
        EndTime: 'Hora de término',
        All: 'Todos',
        DailyAttendance: 'Presença diária',
        TimedRedEnvelope: 'Envelope vermelho temporizado',
        LuckyTurntable: 'Roda da sorte',
        ReliefMoney: 'Dinheiro de alívio',
        RegisterRewards: 'Recompensas de registro',
        Other: 'Outro'
    },
    depositRepeat: {
        full: 'cheio',
        RegisterFirstCharge: 'Registar a primeira carga',
        DailyFirstCharge: 'Primeira carga diária',
        EachRecharge: 'Cada recarga',
        give: 'dar',
        thRecharge: 'ª recarga',
        EveryRecharge: 'Cada recarga',
        EventIntro: 'Instruções de Evento：'
    }
}
